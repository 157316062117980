import { init, captureMessage, captureException, configureScope } from '@sentry/browser';
import severityMap from 'containers/services/Sentry/severityMap';
import { firebase } from 'containers/services/Firebase';

class SentryApi {
  constructor() {
    this.enabled = process.env.REACT_APP_SENTRY;
    this.dsn = process.env.REACT_APP_SENTRY_DSN;
    this.environment = process.env.NODE_ENV || process.env.REACT_APP_ENVIRONMENT;
    this.release = process.env.REACT_APP_RELEASE_NAME;
    this.captureMessage = captureMessage;
    this.severityMap = severityMap;

    //Initialize Sentry
    if (this.enabled === 'true') {
      init({
        dsn: this.dsn,
        enabled: this.enabled,
        environment: this.environment,
        release: this.release,
        debug: true,
      });
    }
  }

  get currentUser() {
    // Retrieve the current user's state. (Returns Promise)
    return firebase
      .getAuthState()
      .then(user => {
        // Authenticated
        return user;
      })
      .catch(error => {
        // Not Authenticated
        return null;
      });
  }

  sendDebug(message, data) {
    const user = this.currentUser;

    if (this.enabled) {
      configureScope(scope => {
        scope.setLevel('debug');
        // User (Auth or Anonymous)
        if (user) {
          scope.setUser('id', user.id);
          scope.setUser('email', user.email);
        }
      });
      captureException({ message: message, data: data });
    }
  }

  sendInfo(message, data) {
    const user = this.currentUser;

    if (this.enabled) {
      configureScope(scope => {
        scope.setLevel('info');
        // User (Auth or Anonymous)
        if (user) {
          scope.setUser('id', user.id);
          scope.setUser('email', user.email);
        }
      });
      captureException({ message: message, data: data });
    }
  }

  sendError(error) {
    if (this.enabled) {
      configureScope(scope => {
        const user = this.currentUser;

        // Level
        scope.setLevel('error');
        // User (Auth or Anonymous)
        if (user) {
          scope.setUser('id', user.id);
          scope.setUser('email', user.email);
        }
      });
      captureException(error);
    }
  }
}

export default SentryApi;
