import React from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CatchError from 'components/ErrorBoundaries/CatchError';

// Material UI Components
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

class Wizard extends React.Component {
  constructor(props) {
    super(props);

    var width;
    if (this.props.steps.length === 1) {
      width = '100%';
    } else {
      if (window.innerWidth < 600) {
        if (this.props.steps.length !== 3) {
          width = '50%';
        } else {
          width = 100 / 3 + '%';
        }
      } else {
        if (this.props.steps.length === 2) {
          width = '50%';
        } else {
          width = 100 / 3 + '%';
        }
      }
    }

    this.state = {
      currentStep: 0,
      nextButton: this.props.steps.length > 1 ? true : false,
      previousButton: false,
      finishButton: this.props.steps.length === 1 ? true : false,
      width: width,
      movingTabStyle: {
        transition: 'transform 0s',
      },
      allStates: {},
    };
    this.navigationStepChange = this.navigationStepChange.bind(this);
    this.refreshAnimation = this.refreshAnimation.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.finishButtonClick = this.finishButtonClick.bind(this);
    this.updateWidth = this.updateWidth.bind(this);
    this.isValidated = this.isValidated.bind(this);
  }

  componentDidMount() {
    var step = this.props.registrationStore.currentStep;
    this.navigationStepChange(step, '0');
    window.addEventListener('resize', this.updateWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth);
  }

  updateWidth() {
    this.refreshAnimation(this.props.registrationStore.currentStep);
  }

  isValidated = () => {
    if (this.props.validate) {
      if (this.props.steps[this.props.registrationStore.currentStep].stepId === 'profile') {
        return this.props.registrationStore.isUserProfileComplete;
      }

      if (this.props.steps[this.props.registrationStore.currentStep].stepId === 'company') {
        return this.props.registrationStore.isCompanyProfileComplete;
      }
    }
  };

  navigationStepChange(key, time) {
    const isProfileValid = this.props.registrationStore.isUserProfileComplete;
    const isCompanyValid = this.props.registrationStore.isCompanyProfileComplete;

    if (this.props.steps) {
      const isValid =
        key === 0 ? isProfileValid : key === 1 ? isCompanyValid : key === 2 ? isProfileValid && isCompanyValid : false;
      if (isValid || key === 0) {
        this.setState({
          nextButton: this.props.steps.length > key + 1 ? true : false,
          previousButton: key > 0 ? true : false,
          finishButton: this.props.steps.length === key + 1 ? true : false,
        });
        this.props.registrationStore.setField('currentStep', key);
        this.refreshAnimation(key, time);
      }
    }
  }

  nextButtonClick() {
    var key = this.props.registrationStore.currentStep + 1;
    this.setState({
      nextButton: this.props.steps.length > key + 1 ? true : false,
      previousButton: key > 0 ? true : false,
      finishButton: this.props.steps.length === key + 1 ? true : false,
    });
    this.props.registrationStore.setField('currentStep', key);
    this.refreshAnimation(key, '0.5');
  }

  previousButtonClick() {
    var key = this.props.registrationStore.currentStep - 1;
    if (key >= 0) {
      this.setState({
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false,
      });
      this.props.registrationStore.setField('currentStep', key);
      this.refreshAnimation(key, '0.5');
    }
  }

  finishButtonClick() {
    if (
      this.props.validate &&
      ((this[this.props.steps[this.props.registrationStore.currentStep].stepId].isValidated !== undefined &&
        this[this.props.steps[this.props.registrationStore.currentStep].stepId].isValidated()) ||
        this[this.props.steps[this.props.registrationStore.currentStep].stepId].isValidated === undefined) &&
      this.props.finishButtonClick !== undefined
    ) {
      this.props.finishButtonClick();
    }
  }

  refreshAnimation(index, time) {
    var total = this.props.steps.length;
    var li_width = 100 / total;
    var total_steps = this.props.steps.length;
    var move_distance = this.refs.wizard.children[0].offsetWidth / total_steps;
    var index_temp = index;
    var vertical_level = 0;

    var mobile_device = window.innerWidth < 600 && total > 3;

    if (mobile_device) {
      move_distance = this.refs.wizard.children[0].offsetWidth / 2;
      index_temp = index % 2;
      li_width = 50;
    }

    this.setState({ width: li_width + '%' });

    var step_width = move_distance;
    move_distance = move_distance * index_temp;

    var current = index + 1;
    if (current === 1 || (mobile_device === true && index % 2 === 0)) {
      move_distance -= 8;
    } else if (current === total_steps || (mobile_device === true && index % 2 === 1)) {
      move_distance += 8;
    }

    if (mobile_device) {
      vertical_level = parseInt(index / 2, 10);
      vertical_level = vertical_level * 38;
    }

    var movingTabStyle = {
      width: step_width,
      transform: 'translate3d(' + move_distance + 'px, ' + vertical_level + 'px, 0)',
      transition: 'all ' + time + 's cubic-bezier(0.29, 1.42, 0.79, 1)', //'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'
    };
    this.setState({ movingTabStyle: movingTabStyle });
  }

  render() {
    const { classes, title, subtitle, steps, logo } = this.props;
    return (
      <div className={classes.root} ref='wizard'>
        <Grid container direction='column' justify='center' className={classes.wizardHeader}>
          <Grid item xs={12} className={classes.wizardHeaderMedia}>
            {logo ? <img alt='VendorBadge' src={logo()} /> : null}
          </Grid>
          <Grid item>
            <Typography variant='h6'>{title}</Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle1'>
              {steps.map((prop, key) => {
                return this.props.registrationStore.currentStep === key
                  ? prop.stepSubtitle
                    ? prop.stepSubtitle
                    : subtitle
                  : null;
              })}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.wizardNavigation}>
          <ul className={classes.nav}>
            {steps.map((prop, key) => {
              return (
                <li
                  className={classes.steps}
                  key={key}
                  style={{ width: this.state.width }}
                  onClick={() => this.navigationStepChange(key, '0.5')}
                >
                  <Typography className={classes.stepsAnchor}>{prop.stepName}</Typography>
                </li>
              );
            })}
          </ul>
          <Button variant='contained' color='primary' className={classes.movingTab} style={this.state.movingTabStyle}>
            {steps[this.props.registrationStore.currentStep].stepName}
          </Button>
        </div>
        <div className={classes.content}>
          {steps.map((prop, key) => {
            const stepContentClasses = classNames({
              [classes.stepContentActive]: this.props.registrationStore.currentStep === key,
              [classes.stepContent]: this.props.registrationStore.currentStep !== key,
            });

            return (
              <div className={stepContentClasses} key={key}>
                <CatchError>
                  <prop.stepComponent innerRef={this[prop.stepId]} allStates={this.state.allStates} />
                </CatchError>
              </div>
            );
          })}
        </div>
        <div className={classes.footer}>
          <div className={classes.left}>
            <Button
              variant='contained'
              disabled={!this.state.previousButton}
              className={this.props.previousButtonClasses}
              onClick={() => this.previousButtonClick()}
            >
              {this.props.previousButtonText}
            </Button>
          </div>
          <div className={classes.right}>
            {this.state.nextButton ? (
              <Button
                variant='contained'
                disabled={!this.isValidated()}
                color='primary'
                className={this.props.nextButtonClasses}
                onClick={() => this.nextButtonClick()}
              >
                {this.props.nextButtonText}
              </Button>
            ) : null}
            {/* {this.state.finishButton ? (
                <Button variant='contained' disabled={!profileComplete || !companyComplete} color='primary' className={this.finishButtonClasses} onClick={() => this.finishButtonClick()}>
                  {this.props.finishButtonText}
                </Button>
              ) : null} */}
          </div>
          <div className={classes.clearfix} />
        </div>
      </div>
    );
  }
}

Wizard.defaultProps = {
  title: '',
  subtitle: '',
  previousButtonText: 'Previous',
  previousButtonClasses: '',
  nextButtonClasses: '',
  nextButtonText: 'Next',
  finishButtonClasses: '',
  finishButtonText: 'Finish',
};

Wizard.propTypes = {
  classes: PropTypes.object.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.string.isRequired,
      stepComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
      stepId: PropTypes.string.isRequired,
      stepSubtitle: PropTypes.string,
      stepTitle: PropTypes.string,
    })
  ).isRequired,
  logo: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  previousButtonClasses: PropTypes.string,
  previousButtonText: PropTypes.string,
  nextButtonClasses: PropTypes.string,
  nextButtonText: PropTypes.string,
  finishButtonClasses: PropTypes.string,
  finishButtonText: PropTypes.string,
  finishButtonClick: PropTypes.func,
  validate: PropTypes.bool,
};

const styles = theme => ({
  root: {},
  wizardHeader: {
    textAlign: 'center',
    paddingBottom: '10px',
  },
  wizardHeaderMedia: {
    marginBottom: '15px',
  },
  title: {
    margin: '0',
  },
  subtitle: {
    margin: '5px 0 0',
  },
  wizardNavigation: {
    position: 'relative',
  },
  nav: {
    marginTop: '10px',
    paddingLeft: '0',
    marginBottom: '0',
    listStyle: 'none',
    backgroundColor: 'rgba(200, 200, 200, 0.2)',
    '&:after,&:before': {
      display: 'table',
      content: ' ',
    },
    '&:after': {
      boxSizing: 'border-box',
    },
  },
  steps: {
    marginLeft: '0',
    textAlign: 'center',
    position: 'relative',
    display: 'inline-block',
  },
  stepsAnchor: {
    cursor: 'pointer',
    position: 'relative',
    display: 'block',
    padding: '10px 15px',
    transition: 'all .3s',
    border: '0 !important',
    lineHeight: '18px',
    textTransform: 'uppercase',
    fontSize: '12px',
    minWidth: '100px',
    textAlign: 'center',
  },
  notification: {
    display: 'flex',
    justifyContent: 'center',
    alignText: 'center',
  },
  content: {
    marginTop: '2px',
    minHeight: '340px',
    padding: '5px',
  },
  stepContent: {
    display: 'none',
  },
  stepContentActive: {
    display: 'block',
  },
  movingTab: {
    position: 'absolute',
    padding: '11px',
    fontSize: '12px',
    top: '-4px',
    left: '0px',
    borderRadius: '5px',
    boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
  },
  footer: {
    padding: '15px 15px',
  },
  left: {
    float: 'left !important',
  },
  right: {
    float: 'right !important',
  },
  clearfix: {
    '&:after,&:before': {
      display: 'table',
      content: ' ',
    },
    clear: 'both',
  },
});

export default withStyles(styles, { withTheme: true })(inject('registrationStore')(observer(Wizard)));
