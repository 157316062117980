import { configure, observable, action, decorate } from 'mobx';
configure({ enforceActions: 'observed' });

class RouterStore {
  location = {};
  match = {};
  history = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  /**
   * Internal stores getter.  Note: NOT mobx computed
   */
  get stores() {
    return this.rootStore;
  }

  resetStore = () => {
    this.location = {};
    this.match = {};
    this.history = {};
    this.stores.appStore.debug('Router.resetStore() [action] Router store has been reset.');
  };

  setRoute(location, match, history) {
    this.location = location;
    this.match = match;
    this.history = history;
  }
}
decorate(RouterStore, {
  location: observable,
  match: observable,
  history: observable,
  setRoute: action,
  resetStore: action,
});

export default RouterStore;
