
const sentrySeverityMap={
  1: 'debug',
  2: 'info',
  3: 'warning',
  4: 'error',
  5: 'fatal',
}

export default sentrySeverityMap;
