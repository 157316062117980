import React from 'react';
import app from '@firebase/app';
import uuid from 'uuid';
import moment from 'moment';
import errorMap from 'containers/services/Firebase/errorMap';
import config from 'containers/services/Firebase/config';
import { getUserInitials } from 'utils';

import '@firebase/auth';
import '@firebase/database';
import '@firebase/storage';
import '@firebase/functions';
import '@firebase/performance';

app.initializeApp(config);

class Firebase {
  constructor() {
    this._app = app;
    this._auth = app.auth();
    this._data = app.database();
    this._storage = app.storage();
    this._perf = app.performance();
    this._TIMESTAMP = app.database.ServerValue.TIMESTAMP;
    app.auth().setPersistence(app.auth.Auth.Persistence.LOCAL);
  }
  get app() {
    return this._app;
  }
  get auth() {
    return this._auth;
  }
  get data() {
    return this._data;
  }
  get storage() {
    return this._storage;
  }

  // Custom Firebase onCall Functions
  sendVendorWelcomeEmail = app.functions().httpsCallable('emailHttps-sendVendorWelcomeEmail');
  getLocationsByCompanyId = app.functions().httpsCallable('webHttps-getLocationsByCompanyId');
  getUsersByCompanyId = app.functions().httpsCallable('webHttps-getUsersByCompanyId');
  getUsersByLocationId = app.functions().httpsCallable('webHttps-getUsersByLocationId');
  getInviteDetails = app.functions().httpsCallable('webHttps-getInviteDetails');
  processUserInvite = app.functions().httpsCallable('webHttps-processUserInvite');
  sendInviteEmail = app.functions().httpsCallable('webHttps-sendInviteEmail');
  registerNewUser = app.functions().httpsCallable('webHttps-registerNewUser');
  updateCompanyUser = app.functions().httpsCallable('webHttps-updateCompanyUser');
  updateLocationUser = app.functions().httpsCallable('webHttps-updateLocationUser');

  /**
   * Returns Firebase RTB Reference User for User Id
   * @function getUserRefById
   * @param {string} uid firebase.User.uid
   * @returns {Reference} firebase.database.Reference
   */
  getUserRefById = uid => {
    return this.data.ref('users').child(uid);
  };

  /**
   * Returns Firebase RTB Data Object for User Id
   * @function getUserById
   * @param {string} uid firebase.User.uid
   * @returns {Object} User object.
   */
  getUserById = uid => {
    return new Promise((resolve, reject) => {
      this.getUserRefById(uid).once(
        'value',
        snapshot => {
          if (snapshot.val()) {
            resolve(snapshot.val());
          } else {
            resolve();
          }
        },
        error => {
          if (error) {
            reject(error);
          }
        }
      );
    });
  };

  /**
   * Returns Firebase RTB Reference Presence for User Id
   * @function getUserPresenceRefById
   * @param {string} uid firebase.User.uid
   * @returns {Reference} firebase.database.Reference
   */
  getUserPresenceRefById = uid => {
    return this.data.ref('presence').child(uid);
  };

  /**
   * Returns Firebase RTB Presence Data Object for User Id
   * @function getUserPresenceById
   * @param {string} uid firebase.User.uid
   * @returns {Object} User object.
   */
  getUserPresenceById = uid => {
    return this.getUserPresenceRefById(uid).once('value', function(snap) {
      if (snap.val()) {
        return { data: snap.val() };
      } else {
        return { data: null };
      }
    });
  };

  /**
   * Returns User's Firebase Storage Reference for User Id
   * @function getUserStorageRefById
   * @param {string} uid firebase.User.uid
   * @returns {Reference} firebase.storage.Reference
   */
  getUserStorageRefById = uid => {
    return this.storage.ref('users').child(uid);
  };

  /**
   * Returns Firebase RTB Reference User/Profile for User Id
   * @function getUserProfileRefById
   * @param {string} uid firebase.User.uid
   * @returns {Reference} firebase.database.Reference
   */
  getUserProfileRefById = uid => {
    return this.data
      .ref('users')
      .child(uid)
      .child('ProfileInfo');
  };

  /**
   * Returns Firebase RTB Reference User for User Id
   * @function getUserObjectRefById
   * @param {string} uid firebase.User.uid
   * @returns {Reference} firebase.database.Reference
   */
  getUserObjectRefById = uid => {
    return this.data.ref('users').child(uid);
  };

  /**
   * @function inviteNewUser
   * @returns {Promise}
   */
  inviteNewUser(invite) {
    const inviteId = uuid.v4();

    const inviteDetails = {
      id: inviteId,
      companyId: invite.companyId,
      companyName: invite.companyName,
      locationId: invite.locationId || null,
      invitedBy: invite.invitedBy,
      type: invite.type,
      typeId: invite.id,
      role: invite.role,
      email: invite.email,
      timestamp: new Date().toISOString(),
    };

    //TODO read from the database to see if this email
    //has already been invited or not.

    // Returns Promise
    return this.data
      .ref('userInvites')
      .child(inviteId)
      .set(inviteDetails);
  }

  /**
   * Returns Firebase RTB User/Profile data for User Id
   * @function getUserProfileById
   * @param {string} uid firebase.User.uid
   * @returns {Object} User profile object
   */
  getUserProfileById = uid => {
    return new Promise((resolve, reject) => {
      this.getUserProfileRefById(uid).once(
        'value',
        snapshot => {
          if (snapshot.val()) {
            resolve(snapshot.val());
          } else {
            resolve();
          }
        },
        error => {
          if (error) {
            reject(error);
          }
        }
      );
    });
  };

  /**
   * Returns Firebase RTB Reference Subscription for User Id
   * @function getSubscriptionRefById
   * @param {string} uid firebase.User.uid
   * @returns {Reference} firebase.database.Reference
   */
  getSubscriptionRefById = uid => {
    return this.data.ref('subscriptions').child(uid);
  };

  /**
   * Returns Firebase RTB Reference Company for Company Id
   * @function getCompanyRefById
   * @param {string} companyId VendorBadge Company Id
   * @returns {Reference} firebase.database.Reference
   */
  getCompanyRefById = companyId => {
    return this.data.ref('company').child(companyId);
  };

  /**
   * Returns Firebase RTB Company data for Company Id
   * @function getCompanyById
   * @param {string} companyId VendorBadge Company Id
   * @returns {Reference} firebase.database.Reference
   */
  getCompanyById = companyId => {
    return new Promise((resolve, reject) => {
      this.getCompanyRefById(companyId).once(
        'value',
        snapshot => {
          if (snapshot.val()) {
            resolve(snapshot.val());
          } else {
            resolve();
          }
        },
        error => {
          if (error) {
            reject(error);
          }
        }
      );
    });
  };

  /**
   * Returns Firebase RTB Reference Location for Location Id
   * @function getLocationRefById
   * @param {string} locationId VendorBadge Location Id
   * @returns {Reference} firebase.database.Reference
   */
  getLocationRefById = locationId => {
    return this.data.ref('location').child(locationId);
  };

  /**
   * Returns Firebase RTB Location data for Location Id
   * @function getLocationById
   * @param {string} locationId VendorBadge Location Id
   * @returns {object} data = firebase.database.Reference.Snapshot.val()
   */
  getLocationById = locationId => {
    return new Promise((resolve, reject) => {
      this.getLocationRefById(locationId).once(
        'value',
        snapshot => {
          if (snapshot.val()) {
            resolve(snapshot.val());
          } else {
            resolve();
          }
        },
        error => {
          if (error) {
            reject(error);
          }
        }
      );
    });
  };

  /**
   * Fetches company data
   */
  fetchCompanyData = async companyId => {
    return this.getCompanyById(companyId)
      .then(company => {
        return {
          id: company.id,
          name: company.name,
          phone: company.phone,
          bio: company.bio,
          type: company.type,
          websiteUrl: company.websiteUrl,
          users: company.users,
        };
      })
      .catch(error => {
        return null;
      });
  };

  /**
   * Fetches location data for CompanyStore
   */
  fetchLocationData = async locationId => {
    return this.getLocationById(locationId)
      .then(location => {
        return {
          id: location.id,
          name: location.name,
          address: location.address,
          companyId: location.companyId,
          users: location.users,
          cityState: location.address.city + ', ' + location.address.state,
          active: location.active,
          primary: location.primary,
        };
      })
      .catch(error => {
        return null;
      });
  };

  /**
   * Fetches user data
   */
  fetchUserData = async userId => {
    return this.getUserProfileById(userId)
      .then(profile => {
        return {
          uid: userId,
          displayName: profile.displayName,
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email,
          avatarUrl: profile.photoUrl || '',
          initials: profile.initials || getUserInitials(profile.firstName, profile.lastName),
        };
      })
      .catch(error => {
        return null;
      });
  };

  /**
   * Saves an image to the current authenticated user's storage bucket
   * @function saveImageToCurrentUserStorage
   * @param {string} url Url to the image to be saved.
   * @param {function} onProgress Callback function executed during upload progress.
   * @param {function} onError Callback function executed on error.
   * @param {function} onSuccess Callback function executed on completion.
   */
  saveImageToCurrentUserStorage = (url, onProgress, onError, onSuccess) => {
    const self = this;
    const user = self.getCurrentUser();
    const currUserStorageRef = self.getUserStorageRef(user.uid);
    const fileName = url.substr(url.lastIndexOf('/') + 1);

    // Fetch data from temp storage and upload to Firebase Storage
    try {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';

      xhr.onload = function(event) {
        const blob = xhr.response;
        const uploadTask = currUserStorageRef.child(fileName).put(blob);
        uploadTask.on(
          'state_changed',
          snapshot => {
            // Progress
            if (onProgress) {
              onProgress({
                totalBytes: snapshot.totalBytes,
                bytesTransferred: snapshot.bytesTransferred,
              });
            }
          },
          error => {
            // Error
            throw new Error(this.getErrorMessage(error));
          },
          result => {
            // Success
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              if (onSuccess) {
                onSuccess(downloadURL);
              }
            });
          }
        );
      };
      xhr.open('GET', url);
      xhr.send();
    } catch (error) {
      if (onError) {
        throw new Error(this.getErrorMessage(error));
      }
    }
  };

  /**
   * Retrieves an enhanced error object containing error plus code, severity, and ui message.
   * @function getErrorMessage
   * @param {Object} error
   * @returns {Object} { code, severity, message, error }
   */
  getErrorMessage(error) {

    let newError = {
      code: error.code,
      severity: 4,
      message: `There has been an issue processing your request.  The support team has been notified.`,
      error: error,
    };

    // If error is from https function, it will have invalid-argument, check details for code.
    if (error.code === 'invalid-argument') {
      newError.code = error.details.code;
    }

    errorMap.forEach(map => {
      if (map.code == newError.code) {
        newError = {
          code: newError.code,
          severity: map.severity,
          message: map.message,
          error: error,
        };
      }
    });

    return newError;
  }

  /**
   * Creates a new user account associated with the specified email address and password.
   * @function createUserWithEmailAndPassword
   * @param {string} email The user's email address.
   * @param {string} password The user's chosen password.
   * @returns {Promise<UserCredential>
   */
  createUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);

  /**
   * Asynchronously signs in using an email and password.
   * @function signInWithEmailAndPassword
   * @param {string} email The users email address.
   * @param {string} password The users password.
   * @return {Promise<UserCredential>}
   */
  signInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  /**
   * Signs out the current user.
   * @function signOut
   * @returns {Promise<void>}
   */
  signOut = () => this.auth.signOut();

  /**
   * Sends a password reset email to the given email address.
   * @function sendPasswordResetEmail
   * @param {string} email The email address with the password to be reset.
   * @returns {Promise<void>}
   */
  sendPasswordResetEmail = email => this.auth.sendPasswordResetEmail(email);

  /**
   * Checks a password reset code sent to the user by email
   * @function verifyPasswordResetCode
   * @param {string} code A verification code sent to the user.
   * @returns {Promise<string>}
   */
  verifyPasswordResetCode = code => this.auth.verifyPasswordResetCode(code);

  /**
   * Applies a verification code sent to the user by email.
   * @function applyActionCode
   * @param {string} code A verification code sent to the user.
   * @returns {Promise<void>}
   */
  applyActionCode = code => this.auth.applyActionCode(code);

  /**
   * Checks a verification code sent to the user by email.
   * @function checkActionCode
   * @param {string} code A verification code sent to the user.
   * @returns {Promise<void>}
   */
  checkActionCode = code => this.auth.checkActionCode(code);

  /**
   * Completes the password reset process, given a confirmation code and new password.
   * @function confirmPasswordReset
   * @param {string} code The confirmation code send via email to the user.
   * @param {string} password The new password.
   * @returns {Promise<void>}
   */
  confirmPasswordReset = (code, password) => this.auth.confirmPasswordReset(code, password);

  /**
   * Creates a Firebase auth listener to get current user auth state.
   * @returns {object} Firebase Auth User object.
   */
  getAuthState = () => {
    return new Promise((resolve, reject) => {
      this.auth.onAuthStateChanged(user => {
        if (user) {
          // Authenticated
          resolve(user);
        } else {
          // Not authenticated
          reject(user);
        }
      });
    });
  };

  /**
   * Fetches all checkin data for a single locationId
   */
  fetchLocationCheckInData = async locationId => {
    return new Promise((resolve, reject) => {
      this.data
        .ref('checkins/officelogs')
        .child(locationId)
        .on('value', snapshot => {
          if (snapshot.exists()) {
            const logs = snapshot.val();
            const checkins = Object.keys(logs).map(key => {
              return this.fetchCheckinData(logs[key]).then(checkin => {
                return checkin;
              });
            });
            // Returns once all promises are resolved
            resolve(Promise.all(checkins));
          } else {
            // No checkins
            resolve(null);
          }
        });
    });
  };

  /**
   * Fetches all checkin data for a single vendor
   */
  fetchVendorCheckInData = async uid => {
    return new Promise((resolve, reject) => {
      this.data
        .ref('checkins/vendorlogs')
        .child(uid)
        .on('value', snapshot => {
          if (snapshot.exists()) {
            const logs = snapshot.val();
            const checkins = Object.keys(logs).map(key => {
              return this.fetchCheckinData(logs[key]).then(checkin => {
                return checkin;
              });
            });
            // Returns once all promises are resolved
            resolve(Promise.all(checkins));
          } else {
            // No checkins
            resolve(null);
          }
        });
    });
  };

  /**
   * Fetches checkin data for a single checkin log
   * If current company or location data is not available, get data from log.
   */
  fetchCheckinData = async checkin => {
    return {
      company: (await this.fetchCompanyData(checkin.companyId)) || { id: checkin.companyId, name: checkin.companyName },
      location: (await this.fetchLocationData(checkin.locationId)) || {
        id: checkin.locationId,
        name: checkin.locationName,
      },
      user: await this.fetchUserData(checkin.uidOfCheckinUser),
      ...checkin,
      checkInDtTm: moment
        .utc(checkin.formattedDate + ' ' + checkin.formattedTime)
        .local()
        .format(),
      checkInDt: moment
        .utc(checkin.formattedDate + ' ' + checkin.formattedTime)
        .local()
        .format('MM/DD/YYYY'),
      checkInTm: moment
        .utc(checkin.formattedDate + ' ' + checkin.formattedTime)
        .local()
        .format('LT'),
    };
  };

  /**
   * Returns the Medical Practice check-in logs.
   * @function getCompanyCheckins
   * @param {array} locations Array of company location ids.
   * @returns {object} Company checkin logs object.
   */
  getCompanyCheckins = async locations => {
    const companyCheckins = locations.map(locationId => {
      return this.fetchLocationCheckInData(locationId).then(checkins => {
        if (checkins) {
          return checkins;
        }
      });
    });
    // Filter out any undefined values and return
    return Promise.all(companyCheckins).then(checkins => {
      let allCheckins = [];
      checkins
        .filter(value => typeof value !== 'undefined')
        .forEach(checkins => {
          checkins.forEach(checkin => {
            allCheckins.push(checkin);
          });
        });
      return allCheckins;
    });
  };

  /**
   * Returns the Medical Practice check-in logs for a single location.
   * @function getLocationCheckins
   * @param {string} locationId Location unique identifier.
   * @returns {object} Location checkin logs object.
   */
  getLocationCheckins = async locationId => {
    // Filter out any undefined values and return
    return this.fetchLocationCheckInData(locationId).then(checkins => {
      if (checkins) {
        return checkins;
      } else {
        return [];
      }
    });
  };

  /**
   * Returns the vendor check-in logs.
   * @function getVendorCheckins
   * @param {string} uid User unique identifier.
   * @returns {object} Vendor checkin logs object.
   */
  getVendorCheckins = async uid => {
    // Filter out any undefined values and return
    return this.fetchVendorCheckInData(uid).then(checkins => {
      if (checkins) {
        return checkins;
      } else {
        return [];
      }
    });
  };

  /**
   * Manual Location Checkin
   * @param {form} form Form data
   */
  addCheckInLog = formData => {
    return new Promise((resolve, reject) => {
      this.data
        .ref('checkins/officelogs/' + formData.locationId)
        .child(formData.timeInTicks)
        .set(formData)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

/**
 * Create instance of Firebase.
 */
const firebase = new Firebase();

/**
 * Create FirebaseContext reference.
 */
const FirebaseContext = React.createContext();

/**
 * Firebase React Hook
 */
function useFirebase() {
  const context = React.useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error('useFirebase must be within a FirebaseProvider.');
  }
  return context;
}

/**
 * Firebase context provider.
 * @param {object} children React component children.
 */
function FirebaseProvider({ children }) {
  return <FirebaseContext.Provider value={firebase}>{children}</FirebaseContext.Provider>;
}

export { firebase, FirebaseProvider, useFirebase };
