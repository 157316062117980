const stateCodes = [
  { abbr: 'AK', state: 'Alaska', code: 54 },
  { abbr: 'AL', state: 'Alabama', code: 1 },
  { abbr: 'AR', state: 'Arkansas', code: 3 },
  { abbr: 'AZ', state: 'Arizona', code: 2 },
  { abbr: 'CA', state: 'California', code: 4 },
  { abbr: 'CO', state: 'Colorado', code: 5 },
  { abbr: 'CT', state: 'Connecticut', code: 6 },
  { abbr: 'DC', state: 'District of Columbia', code: 8 },
  { abbr: 'DE', state: 'Delaware', code: 7 },
  { abbr: 'FL', state: 'Florida', code: 9 },
  { abbr: 'GA', state: 'Georgia', code: 10 },
  { abbr: 'HI', state: 'Hawaii', code: 52 },
  { abbr: 'IA', state: 'Iowa', code: 14 },
  { abbr: 'ID', state: 'Idaho', code: 11 },
  { abbr: 'IL', state: 'Illinois', code: 12 },
  { abbr: 'IN', state: 'Indiana', code: 13 },
  { abbr: 'KS', state: 'Kansas', code: 15 },
  { abbr: 'KY', state: 'Kentucky', code: 16 },
  { abbr: 'LA', state: 'Louisiana', code: 17 },
  { abbr: 'MA', state: 'Massachusetts', code: 20 },
  { abbr: 'MD', state: 'Maryland', code: 19 },
  { abbr: 'ME', state: 'Maine', code: 18 },
  { abbr: 'MI', state: 'Michigan', code: 21 },
  { abbr: 'MN', state: 'Minnesota', code: 22 },
  { abbr: 'MO', state: 'Missouri', code: 24 },
  { abbr: 'MS', state: 'Mississippi', code: 23 },
  { abbr: 'MT', state: 'Montana', code: 25 },
  { abbr: 'NC', state: 'North Carolina', code: 32 },
  { abbr: 'ND', state: 'North Dakota', code: 33 },
  { abbr: 'NE', state: 'Nebraska', code: 26 },
  { abbr: 'NH', state: 'New Hampshire', code: 28 },
  { abbr: 'NJ', state: 'New Jersey', code: 29 },
  { abbr: 'NM', state: 'New Mexico', code: 30 },
  { abbr: 'NV', state: 'Nevada', code: 27 },
  { abbr: 'NY', state: 'New York', code: 31 },
  { abbr: 'OH', state: 'Ohio', code: 34 },
  { abbr: 'OK', state: 'Oklahoma', code: 35 },
  { abbr: 'OR', state: 'Oregon', code: 36 },
  { abbr: 'PA', state: 'Pennsylvania', code: 37 },
  { abbr: 'RI', state: 'Rhode Island', code: 38 },
  { abbr: 'SC', state: 'South Carolina', code: 39 },
  { abbr: 'SD', state: 'South Dakota', code: 40 },
  { abbr: 'TN', state: 'Tennessee', code: 41 },
  { abbr: 'TX', state: 'Texas', code: 42 },
  { abbr: 'UT', state: 'Utah', code: 43 },
  { abbr: 'VA', state: 'Virginia', code: 45 },
  { abbr: 'VT', state: 'Vermont', code: 44 },
  { abbr: 'WA', state: 'Washington', code: 46 },
  { abbr: 'WI', state: 'Wisconsin', code: 48 },
  { abbr: 'WV', state: 'West Virginia', code: 47 },
  { abbr: 'WY', state: 'Wyoming', code: 49 }
]

export default stateCodes;