const componentMap = {
  administrative_area_level_1: 'state',
  administrative_area_level_2: 'county',
  administrative_area_level_3: 'administrative_area_level_3',
  administrative_area_level_4: 'administrative_area_level_4',
  administrative_area_level_5: 'administrative_area_level_5',
  colloquial_area: 'colloquial_area',
  sublocality: 'sublocality',
  neighborhood: 'neighborhood',
  premise: 'knownAs',
  natural_feature: 'feature',
  airport: 'airport',
  park: 'park',
  point_of_interest: 'point_of_interest',
  country: 'country',
  locality: 'city',
  postal_code: 'zip',
  postal_code_suffix: 'zip4',
  route: 'street',
  street_number: 'number',
  subpremise: 'address2',
}

const parseAddress = (results) => {
  // Check for valid object
  if (!results || !results.address_components) { 
    return;
  }
  // Stringify to remove bad JSON characters.
  const components = JSON.parse(JSON.stringify(results.address_components));
  const address = {};
  //Details is an object, parse into js address object
  Object.keys(components).forEach(component => {
    address[componentMap[components[component].types[0]]] = { 
      longName: components[component].long_name ? components[component].long_name : '', 
      shortName: components[component].short_name ? components[component].short_name : '',
    };
  });
  return address;
}

export default parseAddress;