import React from 'react';

const withRouterStore = store => WrappedComponent => {
  class WithRouterStore extends React.Component {
    componentDidMount() {
      store.setRoute(this.props.location, this.props.match, this.props.history);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
  
  WithRouterStore.displayName = `WithRouterStore(${WrappedComponent.displayName})`;
  return WithRouterStore;
}

export default withRouterStore;