const errorMap = [
  {
    code: 'auth/invalid-user-token',
    message: 'Your session has expired, please sign-in again.',
    sentry: true,
    severity: 1,
    callback: null,
  },
  {
    code: 'auth/user-token-expired',
    message: 'Your session has expired, please sign-in again.',
    sentry: true,
    severity: 1,
    callback: null,
  },
  { code: 'auth/user-not-found', message: 'Invalid email or password.', sentry: true, severity: 1, callback: null },
  {
    code: 'auth/network-request-failed',
    message: 'Someone pulled the plug, please try your request again.',
    sentry: true,
    severity: 2,
    callback: null,
  },
  {
    code: 'auth/too-many-requests',
    message: 'Too many attempts.  Please wait a few minutes and try again.',
    sentry: true,
    severity: 3,
    callback: null,
  },
  { code: 'auth/wrong-password', message: 'Invalid email or password.', sentry: true, severity: 1, callback: null },
  {
    code: 'auth/weak-password',
    message:
      'It looks like your password is not quite strong enough.  Please make sure it is a minimum of 8 characters, contains one uppercase letter, one lowercase letter, and has at least one number.',
    sentry: true,
    severity: 1,
    callback: null,
  },
  {
    code: 'auth/email-already-in-use',
    message: "Are you sure you don't have an account?  That email is already registered.",
    sentry: true,
    severity: 2,
    callback: null,
  },
  {
    code: 'auth/email-already-exists',
    message: "Are you sure you don't have an account?  That email is already registered.",
    sentry: true,
    severity: 2,
    callback: null,
  },
  {
    code: 'auth/invalid-email',
    message: 'It looks like that email is invalid.  Please make sure it is the correct format and try again.',
    sentry: true,
    severity: 1,
    callback: null,
  },
  {
    code: 'auth/app-deleted',
    message: 'Someone pulled the plug!  Our support team has been notified.  Please close your browser and try again.',
    sentry: true,
    severity: 5,
    callback: null,
  },
  {
    code: 'auth/app-not-authorized',
    message:
      'Are you sure you are authorized to do that?  Our support team has been notified.  Please close your browser and try again.',
    sentry: true,
    severity: 4,
    callback: null,
  },
  {
    code: 'auth/argument-error',
    message:
      "That didn't seem to work.  Our support group will take a look at that request, in the meantime, close your browser and try again.",
    sentry: true,
    severity: 4,
    callback: null,
  },
  {
    code: 'auth/invalid-api-key',
    message: 'Someone pulled the plug!  Our support team has been notified.  Please close your browser and try again.',
    sentry: true,
    severity: 5,
    callback: null,
  },
  {
    code: 'auth/operation-not-allowed',
    message:
      "Well it appears that you don't have the permissions to do that.  Our support team has been notified.  If this message is in error, please close your browser and try again.",
    sentry: true,
    severity: 3,
    callback: null,
  },
  {
    code: 'auth/requires-recent-login',
    message: 'That operation requires us to re-confirm who you are.  Click here to confirm.',
    sentry: true,
    severity: 2,
    callback: 'LoginModal',
  },
  {
    code: 'auth/unauthorized-domain',
    message: 'It looks like you are not on an authorized VendorBadge domain.  Please check your URL and try again.',
    sentry: true,
    severity: 4,
    callback: null,
  },
  {
    code: 'auth/user-disabled',
    message:
      'Looks like there may be an issue with your account.  Our support team has been notified.  Please contact them at support@vendorbadge.com.',
    sentry: true,
    severity: 4,
    callback: 'EmailSupport',
  },
  {
    code: 'auth/web-storage-unsupported',
    message:
      'Web storage is required to run our application.  Please enable web storage in your browser and try again.',
    sentry: true,
    severity: 2,
    callback: null,
  },
  {
    code: 'auth/invalid-action-code',
    message: 'It looks like this verification url is invalid, or has expired.',
    sentry: true,
    severity: 2,
    callback: null,
  },
  {
    code: 'auth/expired-action-code',
    message: 'It looks like this verification url has expired.',
    sentry: true,
    severity: 2,
    callback: null,
  },
  {
    code: 'auth/invalid-verification-code',
    message: 'It looks the verification code is invalid, or has expired.',
    sentry: true,
    severity: 2,
    callback: null,
  },
  {
    code: 'auth/missing-verification-code',
    message: 'The verification code is required.',
    sentry: true,
    severity: 2,
    callback: null,
  },
];

export default errorMap;
