/**
 * Returns uppercase initials for user's first and last name.
 * @param {string} firstname User's first name.
 * @param {string} lastName User's last name.
 * @return {string} User's uppercase initials.
 */
export const getFormattedDate = dateString => {
  const date = new Date(dateString);
  return (
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1) +
    '-' +
    date.getDate() +
    ' ' +
    date.getHours() +
    ':' +
    date.getMinutes() +
    ':' +
    date.getSeconds()
  );
};
