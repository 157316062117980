import React from 'react';
import { action, decorate } from 'mobx';
import { shallowEqual } from 'utils';

import AuthStore from 'stores/Auth';
import AppStore from 'stores/AppStore';
import UserStore from 'stores/User';
import DeviceStore from 'stores/DeviceStore';
import RegistrationStore from 'stores/RegistrationStore';
import PortalStore from 'stores/PortalStore';
import RouterStore from 'stores/RouterStore';
import LoginStore from 'stores/LoginStore';
import CompanyStore from 'stores/CompanyStore';
import LocationStore from 'stores/LocationStore';
import VendorStore from 'stores/Vendor';

class RootStore {
  constructor() {
    /**
     * Order of initialization is important, make sure app, auth, device, and router order is not changed.
     */
    this.appStore = new AppStore(this);
    this.authStore = new AuthStore(this);
    this.deviceStore = new DeviceStore(this);
    this.routerStore = new RouterStore(this);
    this.loginStore = new LoginStore(this);
    this.userStore = new UserStore(this);
    this.portalStore = new PortalStore(this);
    this.registrationStore = new RegistrationStore(this);
    this.companyStore = new CompanyStore(this);
    this.locationStore = new LocationStore(this);
    this.vendorStore = new VendorStore(this);
  }

  resetStores = () => {
    this.authStore.reset();
    this.userStore.reset();
    this.loginStore.resetStore();
    this.registrationStore.reset();
    this.routerStore.resetStore();
    this.companyStore.reset();
    this.locationStore.reset();
  };
}
decorate(RootStore, {
  resetStores: action,
});

const StoreContext = React.createContext({});

function useStores() {
  const context = React.useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useStores must be within a StoreProvider.');
  }
  return context;
}

const rootStore = new RootStore();

function StoreProvider({ children, ...rootStore }) {
  const parentValue = React.useContext(StoreContext);
  const value = React.useRef({
    ...parentValue,
    ...rootStore,
  }).current;

  if (process.env.NODE_ENV !== 'production') {
    const newValue = { ...value, ...rootStore }; // spread in previous state for the context based stores
    if (!shallowEqual(value, newValue)) {
      throw new Error(
        'StoreProvider: The set of provided stores has changed. Please avoid changing stores as the change might not propagate to all children'
      );
    }
  }

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
}

export { StoreContext, StoreProvider, useStores, rootStore };
